'use client';
import React from 'react';

import { ModalContent, ModalBody } from '@heroui/react';

import CommonModal from '@/app/components/CommonModal';

import Button from '../Button';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  message?: string;
};

const QuestionModal = ({ isOpen, onClose, onSuccess, message }: Props) => {
  return (
    <CommonModal
      onESC={onClose}
      isOpen={isOpen}
      closeButton={<div></div>}
      className='top-10'
      scrollBehavior="inside"
    >
      <ModalContent>
        <ModalBody className='max-h-[100vh] overflow-y-auto'>
          <div className='flex flex-col items-center py-4'>
            <p className='mb-8 text-lg font-bold dark:text-white'>{message}</p>
            <div className='grid w-full grid-cols-2 gap-x-4'>
              <Button label='No' className='bg-[#726b6b]' variant='outline' onClick={onClose} />
              <Button label='Yes' className='bg-btcs-pink' onClick={onSuccess} />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </CommonModal>
  );
};

export default QuestionModal;
