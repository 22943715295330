import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError, } from '@reduxjs/toolkit/dist/query';
import { getCookie, setCookie } from 'cookies-next';

import { generateUniqueID } from '@/helpers';
import { REQUEST_API_URL } from '@/services/configs';

const baseUrl = `${REQUEST_API_URL}/`;
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers) => {
    const token = getCookie('cq_token');
    let deviceToken = getCookie('device');

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    if (!deviceToken) {
      deviceToken = generateUniqueID();
      setCookie('device', deviceToken);
    }
    headers.set('device', deviceToken);
  },
});

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  return baseQuery(args, api, extraOptions);
};

export default customBaseQuery;
