'use client';

import { useState } from 'react';

import { faBookmark, faRightFromBracket, faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteCookie } from 'cookies-next';
import Link  from 'next/link';
import { useRouter } from 'next/navigation';
import { signOut, useSession } from 'next-auth/react';

import MultiLevelDropDown from '@/app/components/MultiLevelDropDown';
import QuestionModal from '@/app/components/QuestionModal';
import { chainOptions, TEXT_CONTENTS } from '@/app/constants';
import { useAccountModal } from '@/app/contexts/AccountModalContext';
import useMobileView from '@/hooks/useMobileView';
import { IOption } from '@/types';

import { navigations } from '../../constants';
import RegisterWarningModal from '../RegisterWarningModal';
import SavedSearchModal from '../SavedSearchModal';

import AboutModal from './AboutModal';
import { MobileMenu } from './MobileMenu';
import NavItem from './NavItem';

export default function Nav() {
  const router = useRouter();
  const isMobileView  = useMobileView();
  const { setAccountModal } = useAccountModal();

  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const [aboutModalOpen, setAboutModalOpen] = useState<boolean>(false);
  const [confirmSignOutShow, setConfirmSignOutShow] = useState<boolean>(false);

  const { status } = useSession();

  const onLogout = () => {
    setConfirmSignOutShow(false);
    deleteCookie('cq_token');
    signOut({ callbackUrl: navigations.AI_ANAYTICS });
  };

  const handleBookmarkBtn = (option: IOption) => {
    if(status !== 'authenticated') {
      setWarningModalOpen(true);
      return;
    }
    router.push(`/bookmarks?chain=${option.value}`);
  };

  const handleMySearchesBtn = () => {
    if(status !== 'authenticated') {
      setWarningModalOpen(true);
      return;
    }
    setSearchModalOpen(true);
  };
  
  const openProfileModal = () => {
    setAccountModal({
      open: true,
      tab: 'Profile'
    });
  };

  return (
    <div className="flex flex-wrap items-center md:flex-row p-6 text-[13px] dark:text-white text-black text-bold justify-between min-h-[8vh]">
      <div className="cursor-pointer underline" onClick={() => setAboutModalOpen(true)}>About</div>
      <div className="flex flex-row">
        <div className="mx-4 underline">
          {status === 'authenticated' ? isMobileView ?
            <MobileMenu
              handleBookmarkBtn={handleBookmarkBtn} 
              handleMySearchesBtn={handleMySearchesBtn}
              openProfileModal={openProfileModal}
              onLogout={onLogout}
            ></MobileMenu>
            :(<div className='flex'>
              <MultiLevelDropDown
                options={chainOptions}
                changeValue={handleBookmarkBtn}
                LabelComponent={<NavItem icon={faBookmark} text='My Bookmarks' />}
              />
              <p className="cursor-pointer mx-3" onClick={handleMySearchesBtn}>
                <FontAwesomeIcon icon={faStar} className="ml-1  fa-solid inline-block pr-2" />
                My Searches
              </p>
              <p className="cursor-pointer mx-3" onClick={openProfileModal}>
                <FontAwesomeIcon icon={faUser} className="ml-1  fa-solid inline-block pr-2" />
                My Account
              </p>
              <p className="cursor-pointer text-btcs-pink underline" onClick={() => setConfirmSignOutShow(true)}>
                <FontAwesomeIcon icon={faRightFromBracket} className="ml-1  w-[1rem] fa-solid inline-block pr-2" />
                Sign Out
              </p>
            </div>) : 
            (
              <Link href={'/login'}>
                <p className='flex items-center'>
                  <FontAwesomeIcon icon={faRightFromBracket} className="ml-1  fa-solid inline-block pr-2" />
                Sign In
                </p>
              </Link>)}
        </div>
        <RegisterWarningModal text='This feature is only available for registered users.'
          isOpen={warningModalOpen}
          setOpen={setWarningModalOpen}
        />
        <SavedSearchModal isOpen={searchModalOpen} setOpen={setSearchModalOpen}/>
        <AboutModal isOpen={aboutModalOpen} setOpen={setAboutModalOpen}/>
        <QuestionModal
          isOpen={confirmSignOutShow}
          onClose={() => setConfirmSignOutShow(false)}
          onSuccess={onLogout}
          message={TEXT_CONTENTS.question.signOut}
        />
      </div>
    </div>
  );
}
