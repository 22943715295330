import { bech32 } from 'bech32';

export function validateBech32Address(address: string) {
  try {
    // Length check
    if (address.length < 14 || address.length > 74) {
      return false;
    }
      
    // Decode the address
    const decoded = bech32.decode(address);

    // Check for valoper HRP
    if (decoded.prefix.endsWith('valoper')) {
      return false; // This is a valoper address, not a user address
    }

    // Add additional checks on decoded data as required
    // ...

    return true; // Address is valid and it's not a valoper address
  } catch (error) {
    return false; // Invalid address or other decoding error
  }
}