'use client';

import { useState } from 'react';

import { deleteCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { signOut } from 'next-auth/react';

import QuestionModal from '@/app/components/QuestionModal';
import ResultModal from '@/app/components/ResultModal';
import { navigations } from '@/app/constants';
import { useChangePasswordMutation, useDeleteAccountMutation, useResetAccountMutation, useTroubleshootMutation, useUpdateAvatarMutation, useUpdateUserDataMutation } from '@/services/profile';
import { PasswordType } from '@/types/profile';

import { Loader } from '../../Loader';

import PasswordResetContent from './PasswordResetContent';
import ProfileForm from './ProfileForm';

type ActionType = 'avatar' | 'update' | 'password' | 'generate' | 'reset' | 'delete' | null;
type ModalType = 'question' | 'result';

export type ModalState = {
  type?: ModalType;
  action?: ActionType;
  message?: string;
  success?: boolean;
  isOpen: boolean;
};

type Props = {
  resetShow: boolean
  setResetShow: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void,
}

function Profile({ resetShow, setResetShow, onClose } :Props){
  const router = useRouter();

  const [updateProfile, updateProfileState] = useUpdateUserDataMutation();
  const [updateAvatar, updateAvatarState] = useUpdateAvatarMutation();
  const [changeCurrentPassword, changePasswordState] = useChangePasswordMutation();
  const [resetAccount, resetAccountState] = useResetAccountMutation();
  const [deleteAccount, deleteAccountState] = useDeleteAccountMutation();
  const [troubleshoot, troubleshootState] = useTroubleshootMutation();
  const profileStates = [
    updateProfileState,
    updateAvatarState,
    changePasswordState,
    resetAccountState,
    deleteAccountState,
    troubleshootState,
  ];
  const isLoading = profileStates.reduce((acc, state) => acc || state.isLoading, false);

  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
  });

  const timeout = new Promise((resolve, reject) => {
    setTimeout(resolve, parseInt(process.env.REACT_APP_SYNC_API_TIMEOUT_SECONDS as string));
  });

  const actionHandler = async () => {
    const blankFormData = new FormData();
    setModalState({ ...modalState, isOpen: false });
    switch (modalState.action) {
    case 'avatar':
      await updateAvatar(blankFormData);
      break;
    case 'delete':
      responseHandler(await deleteAccount(''), 'delete');
      break;
    case 'password':
      await resetAccount('');
      break;
    case 'generate':
      await Promise.race([troubleshoot, timeout]);
      break;
    }
  };

  const resultHandler = () => {
    setModalState({ ...modalState, isOpen: false });
    switch (modalState.action) {
    case 'delete':
      deleteCookie('cq_token');
      signOut({ callbackUrl: navigations.AI_ANAYTICS });
      onClose();
      break;
    case 'update':
      router.push(navigations.AI_ANAYTICS);
      break;
    default:
      break;
    }
  };

  const responseHandler = (res: any, action: ActionType = 'reset') => {
    if (res.data){
      setModalState({
        action,
        type: 'result',
        isOpen: true,
        message: res.data.message
      });
    } else {
      setModalState({
        action,
        type: 'result',
        isOpen: true,
        message: res.error.data.message
      });
    }
  };
  return <>
    {isLoading ? <Loader showLogo text="Loading..." /> : null}
    <PasswordResetContent
      isShow={resetShow}
      onClose={() => setResetShow(false)}
      onSubmit={async (params: PasswordType) => responseHandler(await changeCurrentPassword(params))
      }
    ></PasswordResetContent>
    <QuestionModal
      isOpen={modalState.isOpen && modalState.type === 'question'}
      onClose={() => setModalState({ ...modalState, isOpen: false })}
      onSuccess={actionHandler}
      message={modalState.message}
    />
    <ResultModal
      isOpen={modalState.isOpen && modalState.type === 'result'}
      onClose={resultHandler}
      onSuccess={resultHandler}
      message={modalState.message}
      buttonLabel={modalState.action === 'update' ? 'Go back to Home' : undefined}
    />
    {!resetShow && <ProfileForm 
      setModalState={(action) => setModalState(action)}
      openResetModal={() => setResetShow((prev) => !prev)}
      updateAvatar={(value) => updateAvatar(value)}
      updateUserData={async (value) => responseHandler(await updateProfile(value))}
    />}
  </>;
}

export default Profile;