import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface INavItem {
  onClick?: () => void
  icon: any
  text: string,
}

export default function NavItem({ onClick, icon, text }: INavItem) {
  return (
    <div className="cursor-pointer flex items-center justify-center" onClick={onClick}>
      <FontAwesomeIcon icon={icon} className="ml-1  fa-solid inline-block pr-2"/>
      <span className="whitespace-nowrap">{text}</span>
    </div>
  );
}
