export const QAs = [
  {
    q: 'What is ChainQ?',
    a: 'ChainQ is an AI-powered blockchain analytics platform, making it easy to analyze the complexities of blockchain data. With its intuitive interface and powerful features, including cutting-edge AI, personalized search functionality, and insightful visualizations, ChainQ empowers users of all levels to explore, organize and understand blockchain data.',
  },
  {
    q: 'What blockchains does ChainQ support?',
    a: 'Currently, ChainQ provides data on the Bitcoin (BTC) and Cosmos (ATOM) blockchains. We welcome feedback and suggestions for additional features and blockchains to support on the ChainQ platform. Please reach out to us via the chatbot or email us at chainq@btcs.com.',
  },
  {
    q: 'What kind of questions can I ask ChainQ AI Analytics?',
    a: 'ChainQ AI Analytics have been designed to answer simple analytics questions, see few examples below. Keep in mind, more complex questions require longer processing time, especially for queries involving averages, total counts or sum of values. In most cases, limiting your questions to a specific time-frame (i.e. last month, between November 2023 and March 2024, etc.) greatly reduces the time needed to answer your question. \n\n' +
      'Bitcoin Example Questions:\n\n' +
      '\'What is the highest transaction fee paid in block 593025?\',\n\n' +
      '\'How much Bitcoin has been mined in total as of block 856,470?\',\n\n' +
      '\'How many transactions happened between 1/1/2024 and 4/1/2024?\',\n\n' +
      '\'What was the average Bitcoin transaction amount in block 593025?\',\n\n' +
      'Cosmos Example Questions:\n\n' +
      '\'Which transaction has the highest fee in USD?\',\n\n' +
      '\'What were the daily average transaction fees in last 10 days for each day?\',\n\n' +
      '\'How many transactions were made on 1/1/2024?\',\n\n' +
      '\'What is the total transaction amount in ATOM on 1/1/2024?\''
  },
  {
    q: 'What is ChainQ Custom Search?',
    a: 'ChainQ Custom Search allows you to use a simple web form to search for any specific transactions using the following options:\n\n' +
      '• Transaction start or end dates\n' +
      '• FROM or TO wallet addresses, or both\n' +
      '• USD or BTC or ATOM maximum and minimum amounts \n' +
      '• Transaction hash\n' +
      '• Block number',
  },
  {
    q: 'How are USD values calculated?',
    a: 'Transaction USD values (amount, fee) are calculated using CoinMarketCap pricing.',
  },
  {
    q: 'What are Bitcoin Inputs and Outputs?',
    a: 'To understand how Bitcoin inputs and outputs work, please <a href="https://www.youtube.com/watch?v=BuUPKC6rFlE" target="_blank" style="text-decoration: underline">view this short video.</a>',
  },
  {
    q: 'What Cosmos transaction types are available in ChainQ?',
    a: 'Cosmos blockchain: Send/Receive, Rewards, Delegations, Undelegations and Redelegations',
  },
  {
    q: 'What are My Bookmarks?',
    a: 'The My Bookmarks feature allows users to select specific transactions and save them to a personalized account for future reference. My Bookmarks are only available to registered users.',
  },
  {
    q: 'What are My Searches?',
    a: 'The My Searches feature allows users to save a specific custom search query and save it to your account for future reference. My Searches are only available to registered users.',
  },
  {
    q: 'Does ChainQ provide real-time data?',
    a: 'ChainQ Bitcoin blockchain data is updated approximately once every 10 minutes. Cosmos blockchain data is updated approximately once every 30 minutes.',
  },
  {
    q: 'Does ChainQ provide data visualization?',
    a: 'ChainQ AI Analytics results are automatically displayed on a chart. You can adjust X or Y axis, type of a chart or download the chart as an PNG image.',
  },
  {
    q: 'Can I download my query results?',
    a: 'Yes, to download your query results you must be registered user.',
  },
  {
    q: 'What format can I download my query results?',
    a: 'Query results are available to be exported to CSV by clicking the Download CSV button.',
  },
  {
    q: 'Can I get query results via an API?',
    a: 'Currently, we do not offer any API access. If you are interested in a customized API, please email chainq@btcs.com',
  },
  {
    q: 'Is ChainQ free?',
    a: 'Yes, ChainQ is free to use. As a default only 6 months of most recent transactions are accessible, to access the complete data set simply register for free.',
  },
  {
    q: ' What if I want to download more than 50,000 records?',
    a: 'If you have a need to download more than 50,000 records at a time, please reach out to us via the chatbot or email us at chainq@btcs.com.',
  },
  {
    q: 'What if I’m having a problem with the platform?',
    a: 'If you are having issues with our platform, we encourage you to start a conversation with our team via the chatbot or email us at chainq@btcs.com.',
  },
  {
    q: 'How much historical data does ChainQ provide?',
    a: 'ChainQ provides all Bitcoin blockchain transactions starting 1/9/2009 and Cosmos 4th (cosmoshub-4) version blockchain data starting 2/18/2021. As a default ChainQ displays results for most recent 6 months, to access the complete data users must register for free.',
  },
];
