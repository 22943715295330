'use client';

import { useEffect, useState, useRef } from 'react';

import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IOption } from '@/types';

import { AllCheckOption } from './AllCheckOption';

interface ICustomOption{
  clickHandler?: (data: any) => void
  data: IOption
}

const CustomOption = ({ clickHandler, data }: ICustomOption) => {
  const handleClick = () => {
    if(clickHandler && !data.isDisabled) clickHandler(data);
  };
  return (
    <li onClick={handleClick} className={`flex justify-between w-full rounded-sm p-2 hover:cursor-pointer ${data.isDisabled? 'text-slate-300': 'hover:bg-gray-300'}`}>
      <div className='w-[90%] justify-start flex'>
        <div className='inline-block '>
          { (data.checked === true)
            ? <FontAwesomeIcon icon={faSquareCheck} className="fa-solid lg:w-[1.3rem] w-[1rem] inline-block pr-2 text-red"/>
            : (data.checked === false)
              ? <FontAwesomeIcon icon={faSquare} className="fa-solid lg:w-[1.3rem] w-[1rem] inline-block pr-2 text-red"/>
              : <></>
          }
          {data.value !== 'COMING_SOON' && (
            <>
              {data.faIcon ?
                <FontAwesomeIcon icon={data.faIcon} className="fa-solid lg:w-[1.3rem] w-[1rem] inline-block pr-2 text-red"/> :
              // eslint-disable-next-line @next/next/no-img-element
                <img alt="icon-img" src={ '/chain-icons/' + data.label + '.png' } className='w-[1.4rem] inline-block pr-2'></img>
              }
            </>
          )}
          {data.label}
        </div>
      </div>
      {
        data.subMenu? 
          <div >
            <svg
              className="fill-current h-[20px]
          transition duration-150 ease-in-out -rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div> :
          <></>
      }
    </li >
  );
};

interface IDropdown {
  options: IOption[]
  changeValue: (value: any)=> void
  show: boolean,
  setShow: (value: any)=> void
}

export default function DropDown({ options, changeValue, show, setShow }: IDropdown) {
  const [data, setData] = useState<IOption[] | null>();
  const ref = useRef<HTMLDivElement|null>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);
    if(options)
      setData(JSON.parse(JSON.stringify(options)));

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [options]);

  const changeCheckedData = (option: IOption) => {
    if(data){
      const newData: IOption[] | null = data.map((item: IOption)=>{
        if (item.subMenu){
          item.subMenu = item.subMenu.map((subItem) => {
            if (subItem.value === option.value) subItem.checked = !subItem.checked;
            return subItem;
          });
        } 
        return item;
      });
      changeValue(newData);
    }
  };

  const onClickOption = (option: IOption) => {
    changeValue(option);
  };

  const checkAll = (option: IOption) => {
    if(data){
      const newData: IOption[] | null = data.map((item: IOption)=>{
        if (item.subMenu && item.value === option.value ){
          item.subMenu = item.subMenu.map((subItem) => {
            subItem.checked = true;
            return subItem;
          });
        } 
        return item;
      });
      changeValue(newData);
    }
  };

  const unCheckAll = (option: IOption) => {
    if(data){
      const newData: IOption[] | null = data.map((item: IOption)=>{
        if (item.subMenu && item.value === option.value ){
          item.subMenu = item.subMenu.map((subItem) => {
            subItem.checked = false;
            return subItem;
          });
        } 
        return item;
      });
      changeValue(newData);
    }
  };

  return (
    <ul
      id="menu"
      aria-hidden="false"
      className={`bg-white text-black z-50 border rounded-sm transform absolute ${show ? '' :'hidden'} transition-opacity duration-150 ease-in-out origin-top w-full`}
    >
      {data
        ? data.map((item: IOption, idx: number) => {
          return (
            <div key={idx} onClick={(!item.isDisabled && !item.subMenu) ? () => setShow((prev: boolean) => !prev) : ()=> {}}>
              {item.subMenu
                ? <div className='relative group'>
                  <CustomOption data={item}/>
                  <ul
                    id="menu-lang"
                    aria-hidden="true"
                    className="w-[236px] bg-white border rounded-sm absolute top-0 right-0 md:-right-[236px] transition duration-150 ease-in-out origin-top-left scale-0 group-hover:scale-100"
                  >
                    <AllCheckOption data={item.subMenu} handleCheckAll={() => checkAll(item)} handleUncheckAll={() => unCheckAll(item)}/>
                    {item.subMenu.map((subMenuItem, subIdx) => <div key={subIdx}>
                      <CustomOption data={subMenuItem} clickHandler={(option: IOption)=> changeCheckedData(option) }/>
                    </div>)}
                  </ul>
                </div>
                : <CustomOption data={item} clickHandler={onClickOption}/>
              }
            </div>
          );
        })
        : <></>
      }
    </ul>
  );
};
