'use client';
import { useState, useEffect } from 'react';

import { faSquareCheck, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IOption } from '@/types';

export const AllCheckOption = (props: any) => {
  const { data } = props;
  const [allCheck, setAllCheck] = useState<boolean>(false);

  useEffect(()=>{
    let flag = true;
    data.map((item: IOption) => {
      if(!item.checked){
        flag = false;
      } 
    });
    if(flag) setAllCheck(true);
    else setAllCheck(false);
  }, [data]);

  const handleClick = () => {
    if (!allCheck){
      props.handleCheckAll();
    } else {
      props.handleUncheckAll();
    }
  };
  return <>
    <div className='flex justify-between w-full rounded-sm p-2 hover:cursor-pointer' onClick={handleClick}>
      <div className='w-[90%] justify-start flex'>
        <div className='inline-block'>
          {
            allCheck? <FontAwesomeIcon icon={faSquareCheck} className="fa-solid lg:w-[1.3rem] w-[1rem] inline-block pr-2 text-red"/> :
              <FontAwesomeIcon icon={faSquare} className="fa-solid lg:w-[1.3rem] w-[1rem] inline-block pr-2 text-red"/>
          }
        </div>
        All
      </div>
    </div>
  </>;
};