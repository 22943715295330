import { cloneElement, useEffect, useState, useRef } from 'react';

import { IOption } from '@/types';

import DropDown from './DropDown';

interface IDropdown {
  options: IOption[]
  changeValue: (value: any)=> void
  placeholder?: string
  classNames?: string
  selectedOption?: IOption
  LabelComponent?: any
}

export default function MultiLevelDropDown({ options, changeValue, placeholder, classNames, selectedOption, LabelComponent }: IDropdown) {
  const [option, setOption] = useState<IOption | null>();
  const [show, setShow] = useState<boolean>(false);
  const [data, setData] = useState<IOption[] | null>();
  const ref = useRef<HTMLDivElement|null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);
    if(options)
      setData(JSON.parse(JSON.stringify(options)));

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [options]);

  useEffect(() => {
    if (selectedOption) setOption(selectedOption);
  }, [selectedOption]);

  return (
    <div ref={ref} className={`relative cursor-pointer ${classNames}`}>
      {LabelComponent
        ? cloneElement(LabelComponent,  { onClick: data ? () => setShow(prev => !prev) : () => {} })
        : (
          <div
            aria-haspopup="true"
            aria-controls="menu"
            className="p-4 h-full flex justify-between items-center"
            onClick={data ? () => setShow(prev => !prev) : () => {}}
          >
            <div
              className="overflow-hidden text-ellipsis pr-1 dark:text-white text-black">{option ? option.label : placeholder}</div>
            <span className='inline-block'>
              <svg
                className="fill-current h-[20px] dark:text-white text-black"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </span>
          </div>
        )}
      <DropDown
        options={options}
        changeValue={changeValue}
        show={show}
        setShow={setShow}
      />
    </div>
  );
};