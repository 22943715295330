export const generateUniqueID = () => {
  return `${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const displayDate = (str: string | undefined) => {
  if(!str) return '';
  const date_time = new Date(str);
  const date = date_time.toUTCString().slice(0, -13);
  const hours = date_time.getUTCHours() % 12;
  const mins = date_time.getMinutes();
  const amPm = date_time.getUTCHours() < 12 ? 'am' : 'pm';
  return `${date} ${hours}:${mins < 10 ? '0' + mins : mins}${amPm}`;
};

export const truncateText = (text: string | undefined, length: number = 10) => {
  if (text) {
    return length < 10 ? text : text.substring(0, 4) + '...' + text.substring(text.length - 4, text.length);
  }
  else return '';
};

export const decorateNumber = (number: number, limit: number) => {
  if(!number) return '0';
  return number.toLocaleString(undefined, {
    minimumFractionDigits: limit,
    maximumFractionDigits: limit,
  });
};

export function isDate(value: any) {
  if (value instanceof Date) return true;
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(String(value))) return false;
  const d = new Date(value);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === value;
}

export function convertAIQueryToUrl(query: string): string {
  // We convert ai query to url parameter for better SEO performance.
  // If we send ai query as query parameter, then we can just send query without convert.
  // ' ' -> '-'
  // '/' -> '_SLASH_'
  return query.split(' ').join('-').replace(/\//g, '_SLASH_');
}

export function revertUrlToAIQuery(url: string): string {
  // Revert url to origin ai query which converted in convertAIQueryToUrl.
  // '-' -> ' '
  // '_SLASH_' -> '/'
  return url.split('-').join(' ').replace(/_SLASH_/g, '/');
}
