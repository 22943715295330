'use client';

import { ModalBody, ModalContent, ModalHeader } from '@heroui/react';
import { useRouter } from 'next/navigation';

import CommonModal from '@/app/components/CommonModal';
import { navigations } from '@/app/constants';

type Props = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  text: string;
}
export default function RegisterWarningModal({ isOpen, setOpen, text }: Props) {
  const router = useRouter();
  const onRegister = () => {
    router.push(navigations.REGISTER);
    setOpen(false);
  };
  return <CommonModal onESC={() => setOpen(false)} isOpen={isOpen} closeButton={<div></div>}>
    <ModalContent>
      <ModalHeader>
        <div
          className='ml-auto text-gray-400 bg-transparent hover:cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700 rounded-lg w-8 h-8 inline-flex justify-center items-center'
          onClick={() => setOpen(false)}
        >
          <svg className='w-3 h-3' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
            <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6' />
          </svg>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className='w-full max-w-md mx-auto text-center pb-8 flex-col flex items-center'>
          <p className='pb-3'>
            {text}&nbsp;
            Please <span onClick={onRegister} className='underline text-blue-700 cursor-pointer'>register for free</span>.
          </p>
        </div>
      </ModalBody>
    </ModalContent>
  </CommonModal>;
}
